<template>
  <div class="reigster">
    <div class="location">
      <i class="el-icon-place"></i>
      <el-breadcrumb
        class="el__breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>您当前的位置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>注册</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <ul class="tabs">
        <li class="current">用户注册</li>
      </ul>
      <el-form class="el__form" ref="form" :model="form" :rules="rules">
        <el-form-item prop="name">
          <el-input
            prefix-icon="el-icon-user"
            placeholder="请输入昵称"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            prefix-icon="el-icon-user"
            placeholder="请输入手机号码"
            v-model="form.phone"
          ></el-input>
        </el-form-item>
        <el-form-item prop="phoneCaptchaCode">
          <el-input
            prefix-icon="el-icon-key"
            placeholder="请输入图片验证码"
            v-model="form.phoneCaptchaCode"
            class="captcha__code"
          >
            <el-image
              slot="append"
              @click="getPhoneCaptchaCode"
              :src="form.phoneCaptchaImg"
            ></el-image>
          </el-input>
        </el-form-item>
        <el-form-item prop="smsCode">
          <el-input
            prefix-icon="el-icon-key"
            placeholder="请输入手机验证码"
            v-model="form.smsCode"
          >
            <el-button
              slot="append"
              :disabled="sendDisabled"
              @click="getCode(form.phone, form.phoneCaptchaCode)"
              >{{ smsText }}</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-lock"
            placeholder="请输入密码"
            v-model="form.password"
            show-password
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="password1">
          <el-input
            prefix-icon="el-icon-lock"
            placeholder="请再次确认密码"
            v-model="form.password1"
            show-password
          ></el-input>
        </el-form-item> -->
        <el-form-item prop="captchaCode">
          <el-input
            prefix-icon="el-icon-key"
            placeholder="请输入图片验证码"
            v-model="form.captchaCode"
            class="captcha__code"
          >
            <el-image
              slot="append"
              @click="getCaptchaCode"
              :src="form.captchaImg"
            ></el-image>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="el__btn" @click="submit('form')">注册</el-button>
        </el-form-item>
      </el-form>
      <div class="more" @click="onLogin">
        <div>已有账号？<span>马上登录>></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  checkMobile,
  validatePassStrength,
  validatePassword,
} from "@/utils/tools.js";
import { getCaptcha, getSms, reigster } from "@/api/outh";
export default {
  name: "reigster",
  computed: {},
  data() {
    return {
      smsText: "发送验证码",
      sendDisabled: false,
      count: 60,
      timer: null,
      form: {
        name: "",
        phone: "",
        password: "",
        password1: "",
        smsCode: "",
        smsKey: "",

        phoneCaptchaCode: "",
        phoneCaptchaKey: "",
        phoneCaptchaImg: "",

        captchaCode: "",
        captchaKey: "",
        captchaImg: "",
      },
      rules: {
        phone: [{ validator: checkMobile, trigger: "blur" }],
        name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        password: [
          {
            validator: validatePassStrength,
            trigger: "blur",
          },
        ],
        password1: [
          {
            validator: (rule, value, callback) =>
              validatePassword(rule, value, callback, this.form.password),
            trigger: "blur",
          },
        ],
        phoneCaptchaCode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        smsmCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        captchaCode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getPhoneCaptchaCode();
    this.getCaptchaCode();
  },
  methods: {
    onLogin() {
      this.$router.push("/web/login");
    },
    async getPhoneCaptchaCode() {
      const res = await getCaptcha();
      // console.log(res);
      this.form.phoneCaptchaImg = res.image;
      this.form.phoneCaptchaKey = res.key;
    },
    async getCaptchaCode() {
      const res = await getCaptcha();
      // console.log(res);
      this.form.captchaImg = res.image;
      this.form.captchaKey = res.key;
    },
    getCode(phone, captchaCode) {
      checkMobile(null, phone, (msg) => {
        if (msg) {
          return this.$message.error(msg);
        } else {
          if (!captchaCode) {
            return this.$message.error("图片验证码不能为空");
          } else {
            return this.getSms(phone, this.form.phoneCaptchaKey, captchaCode);
          }
        }
      });
    },
    async getSms(phone, smsKey, smsCode) {
      // const res = await getSms(phone, smsKey, smsCode);
      // this.$message.success("短信验证码发送成功");
      // this.form.smsKey = res.data;
      // this.countTime();

      getSms(phone, smsKey, smsCode)
        .then((res) => {
          this.$message.success("短信验证码发送成功");
          this.form.smsKey = res.data;
          this.countTime();
        })
        .catch((err) => {
          console.log("验证码错误====", err);
          this.getPhoneCaptchaCode();
        });
    },
    countTime() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.sendDisabled = true;
        this.smsText = this.count + "s后获取";
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            this.smsText = this.count + "s后获取";
          } else {
            this.sendDisabled = false;
            clearInterval(this.timer);
            this.timer = null;
            this.smsText = "发送验证码";
          }
        }, 1000);
      }
    },
    submit(formName) {
      const {
        captchaKey,
        captchaCode,
        smsKey,
        smsCode,
        name,
        phone,
        password,
        password1,
      } = this.form;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await reigster(
            captchaKey,
            captchaCode,
            smsKey,
            smsCode,
            name,
            phone,
            password,
            password1
          );
          // console.log(res);
          res && this.$message.success("注册成功");
          setTimeout(() => {
            this.$router.push("/web/login");
          }, 500);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reigster {
  background: #ffffff;
  .location {
    padding: 20px;
    display: flex;
    align-items: center;
    .el__breadcrumb {
      margin-left: 8px;
    }
  }
  .content {
    width: 410px;
    padding: 50px 358px 180px;
    .tabs {
      display: flex;
      li {
        font-size: 16px;
        color: #999999;
        margin-right: 26px;
        padding: 8px 0;
        &.current {
          font-weight: 600;
          color: #333333;
          border-bottom: 4px solid #5cbd31;
        }
      }
    }
    .el__form {
      margin-top: 24px;
      .el-input {
        width: 410px;
      }
      /deep/ .captcha__code {
        .el-input-group__append {
          background-color: #ffffff;
        }
        .el-image {
          width: 90px;
        }
      }
      .el__btn {
        width: 410px;
        background: #5cbd31;
        border: 0;
        color: #ffffff;
      }
    }
    .more {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 15px;
      color: #333333;
      span {
        color: #5cbd31;
      }
    }
  }
}
</style>
